<template>
  <div class="search scroll-box" @scroll="scrollBox($event)">
    <div class="top">
      <div class="back" @click="$router.back(-1)"></div>
      <div class="input-box">
        <input type="text" class="input" placeholder="请搜索课程和讲师" v-model="text" ref='input' @keyup.13="getCourseSearch(1, 'isSearch')">
        <div class="search-btn" @click="getCourseSearch(1, 'isSearch')"></div>
      </div>
    </div>
    <div class="serch-types">
      <span class="serch-type" :class="{'active': serchType==1}" @click="serchType=1">课程</span>
      <span class="serch-type" :class="{'active': serchType==2}" @click="serchType=2">讲师</span>
    </div>
    <div class="hot" v-if="!isSearch">
      <p class="title">热门搜索</p>
      <ul class="hot-list">
        <li class="hot-link" v-for="(item, index) in hotCourse" :key="item.id">
          <div class="ranking" :class="index<3?('no_'+(index+1)):'no_'">{{index>2?index+1:''}}</div>
          <router-link :to="'/recordedInfo/'+item.id" class="hot-title">{{item.name}}</router-link>
        </li>
      </ul>
    </div>
    <div class="result" v-else>
      <div class="result-title">共搜索到<span>{{keywords}}</span>{{num}}个结果</div>
      <ul class="result-list" v-if="courseList">
        <li class="result-info" v-for="item in course" :key="item.id">
          <router-link :to="'/recordedInfo/'+item.id" class="info-link">
            <img class="result-img" :src="item.image">
            <div class="result-content">
              <div class="title vertical-ellipsis">{{item.name}}</div>
              <div class="subtitle">{{item.duration}}课时</div>
              <div class="price">
                  ￥{{item.price}}
                </div>
            </div>
          </router-link>
        </li>
      </ul>
      <ul class="result-list" v-if="teacherList">
        <li class="result-info teacher" v-for="item in course" :key="item.id">
          <router-link :to="'/lecturerHomepage/'+item.id" class="info-link">
            <img class="result-img" :src="item.avatar">
            <div class="result-content">
              <div class="title vertical-ellipsis">
                <span>{{item.name}}</span>
                <div class="marks" v-if="item.level">
                  <div class="mark">{{item.level}}</div>
                </div>
              </div>
              <div class="subtitle">{{item.introduce}}</div>
              <p class="btn">进入主页</p>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="nodata" v-if="isSearch && course.total==0">
        <img class="nodata-img" :src="require('@/assets/no-data.png')">
        <p class="nodata-text">当前搜索无结果</p>
        <router-link :to="'/index'" class="back-index">返回首页</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Search',
  data(){
    return {
      courseList: true,
      teacherList: false,
      serchType: 1,
      keywords: '',    //关键词
      isSearch: false, //是否有搜索操作
      text: '',
      course: [],
      hotCourse: null, //热门搜索
      num: 0,          //数据总个数
      limit: 10,       //一页显示几个
      nowPage: 1,      //当前页码
      isMore: false,   //是否有更多数据
    }
  },
  mounted(){
    this.$refs.input.focus(); //input自动获取光标
    this.getCourseSearch(1); //获取列表
  },
  methods: {
    getCourseSearch(page, isSearch){
      let getUrl = '';

      if(this.serchType == 1){
        getUrl = `/v1/course/courseSearch`;
        this.courseList = true;
        this.teacherList = false;
      }else{
        getUrl = `/v1/teacher/searchTeachers`;
        this.courseList = false;
        this.teacherList = true;
      }

      if(page){
        this.nowPage = page;
      }

      this.$axios.post(getUrl, {
        name: this.text,
        page: this.nowPage,
        pre_page: this.limit
      }).then(res => {
        if(this.nowPage == 1){
          this.course = [];
        }
        this.course = [...this.course, ...res.data.data];
        this.hotCourse = res.data.hot_course;
        this.num = res.data.total;
        this.keywords = this.text;

        if(res.data.total > this.course.length){
          this.more = true;
        }else{
          this.more = false;
        }

        if(isSearch){
          this.isSearch = true;
        }
      })
    },
    scrollBox(e) {
      if(e.target.scrollHeight-e.target.clientHeight == e.target.scrollTop && this.more){
        this.nowPage ++;
        this.getCourseSearch();
      }
    },
  }
}
</script>
<style scoped lang="scss">
.top{
  padding: 8px 30px 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .back{
    width: 72px;
    height: 72px;
    background: url(~@/assets/arrows.png) center center no-repeat;
    background-size: 44px;
    transform:rotate(90deg);
  }
  .input-box{
    box-sizing: border-box;
    width: 630px;
    background-color: #F5F5F5;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    .input{
      width: 520px;
      padding: 18px 0;
      background-color: #F5F5F5;
      font-size: 24px;
      color: #333;
      &::placeholder{
        font-size: 24px;
        color: #999;
      }
    }
    .search-btn{
      width: 72px;
      height: 72px;
      background: url(~@/assets/search-grey-m-ico.png) center center no-repeat;
      background-size: 44px;
    }
  }
}
.hot{
  padding: 44px 28px 0;
  .title{
    height: 36px;
    line-height: 36px;
    padding-left: 46px;
    background: url(~@/assets/hot-ico.png) left center no-repeat;
    background-size: auto 36px;
    font-size: 28px;
    font-weight: 400;
    color: #333;
    margin-bottom: 30px;
  }
  .hot-link{
    display: flex;
    align-items: center;
    padding: 14px 0;
    .hot-title{
      font-size: 26px;
      color: #999;
    }
  }
  .ranking{
    width: 30px;
    height: 30px;
    margin-right: 13px;
    &.no_1{
      background: url(~@/assets/ranking-1-ico.png) center center no-repeat;
      background-size: auto 30px;
    }
    &.no_2{
      background: url(~@/assets/ranking-2-ico.png) center center no-repeat;
      background-size: auto 30px;
    }
    &.no_3{
      background: url(~@/assets/ranking-3-ico.png) center center no-repeat;
      background-size: auto 30px;
    }
    &.no_{
      background: url(~@/assets/ranking-ico.png) center center no-repeat;
      background-size: auto 30px;
      font-size: 18px;
      color: #fff;
      line-height: 25px;
      text-align: center;
    }
  }
}
.result-title{
  padding-top: 44px;
  padding-left: 28px;
  font-size: 26px;
  color: #999;
  span{
    color: #254ED4;
    margin: 0 10px;
  }
}
.result-info{
  padding: 28px;
  .info-link{
    display: flex;
    justify-content: space-between;
  }
  .result-img{
    width: 308px;
    height: 200px;
  }
  .result-content{
    width: 366px;
    .title{
      height: 80px;
      line-height: 40px;
      font-size: 28px;
      color: #333;
    }
    .subtitle{
      font-size: 24px;
      color: #999;
    }
    .price{
      padding-top: 48px;
      font-size: 24px;
      color: #CD1B14;
      font-weight: bold;
    }
  }
  &.teacher{
    .result-img{
      width: 158px;
      height: 208px;
    }
    .result-content{
      width: 510px;
      position: relative;
      .title{
        height: 40px;
        display: flex;
        align-items: center;
        .marks{
          margin: 0 0 0 10px;
          .mark{
            padding: 3px 10px;
            background: rgba(255, 195, 11, 0.14);
            border-radius: 18px 0px 18px 0px;
            font-size: 24px;
            color: #F5A623;
          }
        }
      }
      .subtitle{
        margin-top: 12px;
      }
      .btn{
        width: 128px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        border-radius: 6px;
        border: 1px solid #254ED4;
        font-size: 26px;
        color: #254ED4;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}
.nodata{
  width: 574px;
  padding-top: 200px;
  margin: 0 auto;
  text-align: center;
  .nodata-img{
    width: 100%;
    height: auto;
  }
  .nodata-text{
    font-size: 26px;
    color: #999;
    padding-top: 140px;
  }
  .back-index{
    display: inline-block;
    padding: 10px;
    margin: 48px auto 0;
    font-size: 28px;
    color: #999;
  }
}
.serch-types{
  padding: 32px 14px;
  background: #FFFFFF;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  .serch-type{
    padding: 0 14px;
    font-size: 32px;
    color: #999;
    float: left;
    position: relative;
    &.active{
      color: #333;
    }
    &:first-child::after{
      content: '';
      display: block;
      width: 1px;
      height: 30px;
      background-color: #E5E5E5;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -15px;
    }
  }
}
</style>